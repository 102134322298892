.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10em;
}

.contact-card h2 {
  text-align: center;
}

.contact-card {
  background: rgba(128, 128, 128, .4);
  backdrop-filter: blur(15px);
  border-radius: 30px;
  padding: 3em;
  padding-left: 5em;
  padding-right: 5em;
  padding-bottom: 5em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition-property: width, height, margin-top;
  transition-duration: 0.2s;
  width: 500px;
}

.contact-form textarea {
  resize: vertical;
  outline: none;
}

.contact-form textarea[name="subject"] {
  resize: none;
  outline: none;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea[name="subject"],
.contact-form textarea[name="message"] {
  background-color: rgb(0, 0, 0, .45) !important;
  color: white !important;
  border-color: rgb(0, 0, 0, .3);
  margin-top: .25em;
  margin-bottom: 1em;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea[name="subject"]:focus,
.contact-form textarea[name="message"]:focus,
.contact-form input[type="text"]:active,
.contact-form input[type="email"]:active,
.contact-form textarea:active[name="subject"],
.contact-form textarea:active[name="message"] {
  outline: none;
  box-shadow: none;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px rgb(0, 0, 0, .5) inset !important;
  -webkit-text-fill-color: white !important;
}

.contact-form button {
  margin-top: 1.5em;
  border-radius: 10px;
  padding: 8px 35px 8px 35px;
  background: rgba(128, 128, 128, .4);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
  backdrop-filter: blur(5px);
  border: none;
}

.contact-form button:hover {
  background: rgba(0, 0, 0, .5);
  backdrop-filter: blur(15px);
}

@media (max-width: 1200px) {
  .contact-container {
	margin-top: 10em;
  }
  
  .contact-card {
	width: 400px;
	padding: 5em;
  }
}

@media (max-width: 768px) {
  .contact-container {
	margin-top: 10em;
  }
  
  .contact-card {
	padding: 2em;
  }
}