@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Labrada&display=swap");

/* Header and Navigation */
header {
    background: transparent;
    padding-left: 1rem;
}

.navbar {
  width: 100%;
  height: 100px;
  background-color: transparent;
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.5rem 3rem !important;
  font-size: 1.3rem !important;
}

.navbar-brand {
  margin-right: auto !important;
}

.navbar-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: right;
  margin-right: 3rem;
}

.navbar-nav .nav-link {
  color: white !important;
  font-size: 16px !important;
  letter-spacing: 1px;
  font-weight: 500 !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.navbar-nav .nav-link:hover {
  color: #a9a9a9 !important;
}

.nav-link {
  margin: 1rem;
}

.nav-link:focus,
.nav-link:active {
  outline: none;
  text-decoration: none;
  padding: 5px 30px 5px 30px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
  backdrop-filter: blur(5px);
  background: rgb(0, 0, 0, .4);
  border-radius: 10px;
}

.nav-link:hover {
  font-weight: 600 !important;
  text-decoration: none;
  /*padding-left: 8rem !important;
  transition: padding-left .1s ease-in-out;*/
}

.sticky {
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;

}

.logo {
  font-size: 3rem;
  font-weight: bold;
  color: rgba(255, 255, 255, .6);
  align-items: center;
}

.resumebtn {
  margin-left: 2rem;
  border-radius: 25px;
  background: rgba(128, 128, 128, .4);
  padding: 10px 40px 10px 40px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
  border: none;
  backdrop-filter: blur(5px);
}

.resumebtn:hover {
  background: rgba(255, 255, 255, .1);
  backdrop-filter: blur(15px);
  background-color: transparent;
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
  color: white;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}