/* MobileNavbar.css */

/* Basic styling for Nav Links */
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #fff;
  font-size: 2rem;
  transition: color 0.2s;
}

.bm-item:hover {
  color: rgb(128, 128, 128, 1);
  text-decoration: none;
}

.mobileNav:focus,
.mobileNav:active {
  text-decoration: none !important;
  color: rgb(155, 155, 155, 1);
}

/* Burger Menu styles */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 10px;
  top: 36px;
}

.bm-burger-bars {
  background: #fff;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  font-size: 2em;
  width: calc(100% - 36px);
  right: 0;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  padding: 0.8em;
  font-size: 2em;
}
