.about-card {
  background: rgba(128, 128, 128, .4);
  backdrop-filter: blur(15px);
  border-radius: 30px;
  padding: 5em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: width 0.2s ease-in-out;
  width: 500px;
  overflow: auto;
}

.about-container {
  
  text-align: justify;
  height: auto;
  transition: all 0.2s ease-in-out;
}

.scroll-container {
	display: flex;
	justify-content: center;
	flex-basis: 50%;
}

@media (min-width: 1600px) {
  .about-container {
	justify-content: start;
  }
  
  .scroll-container {
	justify-content: start;
  }
  
  .about-card {
	width: 600px;
	margin-left: 6em;

  }
}

@media (max-width: 550px) {
  .about-container {
	display: block;
	padding-bottom: 8em;
  }
  
  .about-card {
	width: 100% !important;
	padding: 2.5em;
	h2 {
	  font-size: 1.5em !important;
    }
  }

  .scroll-container {
	position: relative;
    height: 50vh;
    overflow-y: scroll;
    padding-top: 5%;
    padding-bottom: 5%; 
	flex-basis: 90%;
  }
  
  .scroll-container::-webkit-scrollbar {
	display: none;
  }
}