/* General styles */
html, body, #root, .App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-height: 100vw;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  
}

main {
  flex: 1;
}

footer {
	min-height: 1rem;
}

.background {
  min-height: 100vh;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: white;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #333;
}

ul {
    list-style: none;
    padding: 0;
}

::selection {
  background: #a9a9a9;
}
::-moz-selection {
  background: #a9a9a9;
}

/* Body styling */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10%;
  justify-content: space-between;
}

/* Home styling */
.hero-container {
	display: flex;
	margin-top: 5%;
	justify-content: center;
	flex-basis: 50%;
}

.hero {
  text-align: left;
  z-index: 0;
}

.hero-name {
  font-size: 5em !important;
  color: white !important;
  font-weight: 600 !important;
  letter-spacing: 2px !important;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 0;
}

.hero-description {
  font-size: 2.5em !important;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 0;
}

.profile-photo {
    width: 150px;
    border-radius: 50%;
}

.cta-btn {
    display: inline-block;
    background: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin-top: 1rem;
}

body, html {
  overflow: hidden;
}

@media (min-width: 1600px) { 
  .hero-container {
	justify-content: end;
  }
  
  .hero {
	margin-right: 6em;
  }
}

@media (max-width: 1024px) {
  .flex-container {
    justify-content: center;
  }
    
  .hero-container {
	display: block;
	justify-content: center;
	padding-bottom: 5%;
	white-space: nowrap;
    text-align: center;
  }
}

@media (max-width: 550px) {
  .flex-container {
    justify-content: center;
	height: 100%;
	width: 100%;
  }
  
  .hero-container {
	display: block;
	padding-bottom: 5%;
	white-space: nowrap;
	justify-content: center;
  }
  .hero-name {
	font-size: 3em !important;
  }

  .hero-description {
	font-size: 1.5em !important;
	text-align: center;
  }
}