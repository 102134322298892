.projects-header{
  font-size: 5rem;
  text-align: center;
  padding: 0;
}

.repo-container {
  position: relative;
  height: 90vh;
  overflow-y: auto;
  padding-top: 2%;
  padding-bottom: 2%; 
  
  /* Creating a fade effect at the scroll overflow -- BROKE BLUR EFFECTS
  --scrollbar-width: 8px;
  --mask-height: 64px;


  --mask-image-content: linear-gradient(
      to bottom,
      transparent,
      black var(--mask-height),
      black calc(100% - var(--mask-height)),
      transparent
  );
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat; */
}

.repo-container::-webkit-scrollbar {
  display: none;
}

body, html {
  overflow: hidden;
}

.repo-card {
  padding: 40px;
  margin: 10px;
  background-color: rgba(128, 128, 128, 0.4);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  height: 500px;
  width: 350px;
}

.repo-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-10px);
  transition: all .5s;
}

.repo-card-link {
  text-decoration: none !important;
  color: white !important;
  display: block;
  width: 350px;
}

.card-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

@media (max-width: 1000px) {
  .projects-header {
	font-size: 3rem;
  }
  
  .repo-card {
    width: 350px;
	height: 500px;
  }

  .project-container {
    padding-left: 0rem !important;
	padding-right: 0rem !important;
  }
}

.card-body {
  flex-grow: 1;
  overflow: auto;
}