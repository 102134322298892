.social-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.social-link {
  display: inline-block;
  margin-right: 10%;
  font-size: 2.5em;
  color: white;
  text-decoration: none;
}

.social-link:hover, .social-link:focus {
  text-decoration: bold;
  color: gray;
}

@media (max-width: 1024px) {
  .social-links {
	justify-content: center;
  }
}